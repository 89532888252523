import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useWorklogBoard } from "../../../../utils/reactQuery/worklog";
import { useFeeds } from "../../../../utils/reactQuery/feeds";
import {
  useBoardName,
  useCommentBoard,
} from "../../../../utils/reactQuery/boards";
import WorklogHistory from "./worklogHistory/WorklogHistory";
import ShowComment from "./commentHistory/ShowComment";
import ListFeedHistory from "./feedHistory/ListFeedHistory";
import Loader from "../../../../components/Loader";
import { useSelector } from "react-redux";

const HistoryItems = () => {
  const [searchParams] = useSearchParams();
  const [newFeedData, setNewFeedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const { id } = useParams();
  const { boardName } = useBoardName(id);
  const filterUserId = searchParams.get("search");
  const filterdate = searchParams.get("date");
  const searchText = useSelector((state) => state.search.searchText);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;

  const { worklogBoard } = useWorklogBoard(id, searchParams.get("milestone"));
  const { commentBoard } = useCommentBoard(id, searchParams.get("milestone"));
  const { feeds } = useFeeds(id, 1, 20000, searchParams.get("milestone"));

  useEffect(() => {
    const mergeAndFilterData = () => {
      setIsLoading(true);
      const mergedData = [];

      // Merge data from APIs
      if (worklogBoard?.data?.data?.responseData) {
        const worklogData = worklogBoard.data.data.responseData.map((item) => ({
          ...item,
          content: "Worklog_content",
        }));
        mergedData.push(...worklogData);
      }

      if (commentBoard?.data?.data?.responseData) {
        const commentData = commentBoard.data.data.responseData.map((item) => ({
          ...item,
          content: "comment_content",
        }));
        mergedData.push(...commentData);
      }

      if (feeds?.data?.data?.responseData) {
        const feedData = feeds.data.data.responseData.map((item) => ({
          ...item,
          content: "history_content",
        }));
        mergedData.push(...feedData);
      }

      // Sort by date in descending order
      const sortedData = mergedData.sort((a, b) => {
        const aDate = new Date(
          a.created_at || a.comment_time || a.at_time
        ).getTime();
        const bDate = new Date(
          b.created_at || b.comment_time || b.at_time
        ).getTime();
        return bDate - aDate;
      });

      // Apply date filter
      const applyDateFilter = (data) => {
        if (!filterdate) return data;
        const dateRange = JSON.parse(filterdate);
        const startDate = new Date(dateRange.startDate);
        const endDate = new Date(dateRange.endDate);
        // Set startDate to the beginning of the day
        startDate.setUTCHours(0, 0, 0, 0);
        startDate.setDate(startDate.getDate() + 1);
        // Set endDate to the end of the day
        endDate.setUTCHours(23, 59, 59, 999);
        endDate.setDate(endDate.getDate() + 1);
        const filtered = data.filter((item) => {
          return (
            (new Date(item.created_at) >= startDate &&
              new Date(item.created_at) <= endDate) ||
            (new Date(item.comment_time) >= startDate &&
              new Date(item.comment_time) <= endDate) ||
            (new Date(item.at_time) >= startDate &&
              new Date(item.at_time) <= endDate)
          );
        });
        return filtered;
      };

      // Apply all filters (date, user ID, search text)
      const applyAllFilters = (data) => {
        return data
          .filter(
            (item) =>
              !filterUserId ||
              [
                item.user_id,
                item.author_id,
                item.response_object?.user_object?.user_id,
              ].includes(Number(filterUserId))
          )
          .filter((item) => {
            const searchLower = searchText?.toLowerCase();
            return (
              !searchText ||
              [
                item.username,
                item.work_performed,
                item.card_id,
                item.comment,
                item.history_text,
                item.history_type,
              ].some((field) =>
                field?.toString().toLowerCase().includes(searchLower)
              )
            );
          });
      };

      const filteredData = applyAllFilters(applyDateFilter(sortedData));
      setNewFeedData(filteredData);
      setFilteredData(filteredData);
      setIsLoading(false);
    };

    if (worklogBoard && commentBoard && feeds) {
      mergeAndFilterData();
    }
  }, [worklogBoard, commentBoard, feeds, filterdate, filterUserId, searchText]);

  if (isLoading) {
    return (
      <div className="loader-center">
        <Loader color={"black"} />
      </div>
    );
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <>
      {currentItems && currentItems.length > 0 ? (
        <Box className="feed-outer-container">
          <Box overflow="auto" flexGrow={1}>
            {currentItems.map((item) => {
              if (item.content === "Worklog_content") {
                return (
                  <WorklogHistory
                    key={item.id}
                    worklog={item}
                    boardName={boardName}
                  />
                );
              }
              if (item.content === "comment_content") {
                return (
                  <ShowComment
                    key={item.id}
                    comment={item}
                    boardName={boardName}
                  />
                );
              }
              if (item.content === "history_content") {
                return (
                  <ListFeedHistory
                    key={item.id}
                    history={item}
                    boardName={boardName}
                  />
                );
              }
              return null;
            })}
          </Box>
          <Box className="feed-pagination-button">
            <Button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>
              Previous
            </Button>
            <Box marginX={2}>{`Page ${currentPage} of ${totalPages}`}</Box>
            <Button
              disabled={currentPage === totalPages}
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }>
              Next
            </Button>
          </Box>
        </Box>
      ) : (
        <Box className="no-record-found-container">No Record Found</Box>
      )}
    </>
  );
};

export default HistoryItems;
