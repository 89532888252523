import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import mobileArrow from "../../../../styles/svg/mobileArrow.svg";
import mobileDots from "../../../../styles/svg/mobileDots.svg";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import BasicGrid from "./detailGrid";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useParams, useSearchParams } from "react-router-dom";
import "./details.scss";
import Box from "@mui/material/Box";
import { useCard } from "../../../../utils/reactQuery/cards";
import Loader from "../../../../components/Loader";
import ChangeStatus from "./components/ChangeStatus";
import AttachFile from "./components/changeBoard/attachFile/AttachFile";
import { currentUserId, isAdmin } from "../../../../utils/constants";
import ChangeBoard from "./components/changeBoard/ChangeBoard";
import DeleteCard from "../Kanban/DeleteCard";
import { clearCheckedIds } from "../../../../redux/CheckboxSclice";
import { useDispatch } from "react-redux";
import { useResponsive } from "../../../../hooks/useResponsive";
import { Drawer } from "@mui/material";
import { descriptionUpdate } from "../../../../sevices/apiDescription";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useRemoveMultipleParams } from "../../../../hooks/useRemoveMultipleParams";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

export default function CustomizedDialogs({
  // status,
  boardNameTitle,
  data,
  cardId,
  boardId,
  // title,
  // cardId,
  showDetails,
  setShowDetails,
  cardsOfSameWorkflowStepId,
  cardsOnBoard,
  // isCompletedDependency,
  // parentCardId,
  // description,
}) {
  const [loading, setLoading] = useState(false);
  const [checkCondition, setCheckCondition] = useState(false);
  const [addAttachment, setAddAttachment] = useState({});
  const [cards, setCards] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(null);
  const [removeMultiParams] = useRemoveMultipleParams();
  // const [attachmentNo, setAttachmentNo] = useState(0)
  const [ishovered, setIsHovered] = useState(false);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const descRef = React.useRef("");
  const ref = React.useRef(false);
  const [isFocus, setIsFocus] = useState(false);
  const isMobile = useResponsive("down", "md");
  const isBetween700And900 = useResponsive("between", 700, 900);
  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);
  console.log(
    "cardsOfSameWorkflowStepId",
    card?.data?.data?.responseData?.parent_card_id
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (cardId && cardsOfSameWorkflowStepId.length > 0) {
      const index = cardsOfSameWorkflowStepId.findIndex(
        (item) => item.card.card_id == cardId
      );
      setCurrentCardIndex(index !== -1 ? index : null);
    }
  }, [cardId, cardsOfSameWorkflowStepId]);

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const accessForDeleteCard =
    isAdmin() ||
    card?.data?.data?.responseData?.board.owner_user_id === currentUserId();

  const conditionCheck =
    isAdmin() ||
    card?.data?.data?.responseData?.board.owner_user_id === currentUserId() ||
    card?.data?.data?.responseData?.assigned_to_user_id === currentUserId() ||
    card?.data?.data?.responseData?.reporter === currentUserId();

  const disableCard =
    isAdmin() ||
    card?.data?.data?.responseData?.board.owner_user_id === currentUserId();

  const handlePrevious = () => {
    console.log("indexp", currentCardIndex);
    if (currentCardIndex > 0) {
      const newIndex = currentCardIndex - 1;
      console.log("indexp", newIndex);
      const newCardId = cardsOfSameWorkflowStepId[newIndex]?.card.card_id;
      updateSearchParams("card_id", newCardId);
      setCurrentCardIndex(newIndex);
    }
  };

  const handleNext = () => {
    console.log("indexn", currentCardIndex, cardsOfSameWorkflowStepId.length);
    if (currentCardIndex < cardsOfSameWorkflowStepId.length - 1) {
      const newIndex = currentCardIndex + 1;
      console.log("indexn", newIndex);
      const newCardId = cardsOfSameWorkflowStepId[newIndex]?.card.card_id;
      updateSearchParams("card_id", newCardId);
      setCurrentCardIndex(newIndex);
    }
  };

  const removeSearchParam = (key) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(key);
    setSearchParams(newSearchParams);
  };
  const handleClose = () => {
    // console.log;
    if (isFocus) {
      handleSave();
    }
    removeMultiParams(["card_id", "brd_id"]);
    // removeSearchParam("card_id");
    // setTimeout(() => {
    //   removeSearchParam("brd_id");
    // }, 300);
    setShowDetails(false);
    dispatch(clearCheckedIds());
  };

  const { mutate: descriptionUpdateMutate } = useMutation({
    mutationFn: descriptionUpdate,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [`card`, cardId] });
      toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("Error updating cardDescription", error);
      toast.error(error.response.data.message);
    },
  });

  const handleSave = (text) => {
    // console.log("On change DescRef ===", descRef?.current.value);
    descriptionUpdateMutate({
      card_id: cardId,
      data: {
        description: descRef?.current?.value,
      },
    });

    // setTextEditer(false);
    // setTimeout(() => {
    //   setCallSave(false);
    // }, 500);
  };
  return (
    <>
      {/* <AttachFile  files={addAttachment} cardId={cardId}/> */}

      {/* <React.Fragment> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showDetails}
        onMouseEnter={() => setIsHovered(true)} // Set hover state
        onMouseLeave={() => setIsHovered(false)} // Reset hover state
        fullScreen={isMobile}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height: isMobile ? "100vh" : "calc(100vh - 30px)",
              borderRadius: isMobile ? "" : "12px",
              margin: isMobile ? "auto" : "15px auto",
              // marginTop: isMobile ? "25px" : "",
              maxWidth: "1180px",
            },
          },
        }}
      >
        {isCardLoading ? (
          <div className="loader-center">
            <Loader color={"black"} />
          </div>
        ) : (
          <>
            <DialogTitle
              sx={{
                m: 0,
                p: 2,
                alignItems: "center",
                backgroundColor: "rgba(242, 246, 254, 1)",
                borderBottom: "1px solid rgba(27, 62, 97, 0.16)",
                // opacity: (() => {
                //   if (disableCard) return 1; // If disableCard is true, set opacity to 1
                //   if (parentCardId && isCompletedDependency) return 1; // If parentCardId is present and isCompletedDependency is true
                //   if (parentCardId && !isCompletedDependency) return 0.5; // If parentCardId is present and isCompletedDependency is false
                //   return 1; // If none of the conditions are met, set opacity to 1
                // })(),

                // pointerEvents: (() => {
                //   if (disableCard) return "auto"; // If disableCard is true, set pointerEvents to auto
                //   if (parentCardId && isCompletedDependency) return "auto"; // If parentCardId is present and isCompletedDependency is true
                //   if (parentCardId && !isCompletedDependency) return "none"; // If parentCardId is present and isCompletedDependency is false
                //   return "auto"; // If none of the conditions are met, set pointerEvents to auto
                // })(),
              }}
              id="customized-dialog-title"
              className="d-flex header"
            >
              <div className="modal-header-container">
                {isMobile && (
                  <img
                    src={mobileArrow}
                    alt=""
                    onClick={handleClose}
                    className="board-owner-avtar-click"
                    style={{ opacity: "1 !important", pointerEvents: "auto" }}
                  />
                )}
                <div
                  className={`${
                    isMobile ? (isBetween700And900 ? "ipad-" : "mobile-") : ""
                  }modal-header-inner-container`}
                >
                  <div className={isMobile ? "mobile-left-heading" : "d-flex"}>
                    <div
                      className={
                        isMobile ? "mobile-project-name" : "project-name d-flex"
                      }
                    >{`${card?.data?.data?.responseData?.board?.key} - ${cardId}`}</div>
                    {/* {
                     (isAdmin() || boardOwnerId == currentUserId() || card?.data?.data?.responseData?.assigned_to_user_id == currentUserId()
                     || card?.data?.data?.responseData?.reporter == currentUserId()) ?(  <ChangeStatus
                       currentStatusId={card?.data.data.responseData?.workflow_step_id}
                       cardId
                     ={cardId}/>) : (<div className="project-status d-flex justify-content-center align-items-center">
                     <button className="project-status-button">{cachedData?.data.data.responseData.status}</button>
                     <div className="project-status-icon">
                       <KeyboardArrowDownIcon />
                     </div>
                   </div>)
                   } */}
                    {card && (
                      <ChangeStatus
                        currentStatusId={
                          card?.data.data.responseData?.workflow_step_id
                        }
                        cardId={cardId}
                        checkCondition={conditionCheck}
                        card={card}
                      />
                    )}
                  </div>
                  {isMobile && (
                    <div className="mobile-attachment">
                      <AttachFile cardId={cardId} isMobile={true} />
                    </div>
                  )}
                </div>

                {!isMobile && (
                  <div className="d-flex right-heading">
                    {card && <ChangeBoard cardId={cardId} card={card} />}
                    <div className="header-attach-btn">
                      <AttachFile cardId={cardId} />
                    </div>
                    <div>
                      {accessForDeleteCard && (
                        <DeleteCard
                          cardId={cardId}
                          boardId={boardId}
                          setShowDetails={setShowDetails}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </DialogTitle>

            {isMobile ? (
              <IconButton
                aria-label="close"
                // onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 9,
                  padding: "8px",
                  color: (theme) => theme.palette.grey[500],
                  "&:hover": {
                    // backgroundColor: "rgba(242, 246, 254, 1)",
                    // backgroung:'none',
                    // color: "none",
                    // transform: "none",
                    // padding: "14px"
                  },
                }}
                onClick={() => {
                  console.log("INSIDE");
                  setFilterDrawer(true);
                }}
              >
                <img src={mobileDots} alt="" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 9,
                  padding: "8px",
                  color: (theme) => theme.palette.grey[500],
                  "&:hover": {
                    // backgroundColor: "rgba(242, 246, 254, 1)",
                    // backgroung:'none',
                    // color: "none",
                    // transform: "none",
                    // padding: "14px"
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
            <DialogContent dividers sx={{ paddingBottom: "50px !important" }}>
              {/* <div className="card-title">
           <UpdateTitle title={data?.title} cardId={cardId} data={data}/>
           </div> */}
              <Typography gutterBottom>
                <BasicGrid
                  // setIsHovered={setIsHovered}
                  description={card?.data.data.responseData?.description}
                  title={card?.data.data.responseData?.title}
                  cardId={cardId}
                  data={card?.data.data.responseData}
                  descRef={descRef}
                  isfocus={setIsFocus}
                  handleDescriptionSave={handleSave}
                  cardsOnBoard={cardsOnBoard}
                  // disableCard={disableCard}
                  // isCompletedDependency={isCompletedDependency}
                  // parentCardId={parentCardId}
                />
              </Typography>
            </DialogContent>
            {ishovered && currentCardIndex > 0 && (
              <IconButton
                aria-label="previous"
                onClick={handlePrevious}
                sx={{
                  position: "absolute",
                  left: 9,
                  bottom: "50%",
                  padding: "12px",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  borderRadius: "50%",
                  // color: (theme) => theme.palette.primary.main,
                  zIndex: 1000,
                  transition: "all 0.3s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
            )}
            {ishovered &&
              currentCardIndex < cardsOfSameWorkflowStepId.length - 1 && (
                <IconButton
                  aria-label="next"
                  onClick={handleNext}
                  sx={{
                    position: "absolute",
                    right: 9,
                    bottom: "50%",
                    padding: "12px",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    borderRadius: "50%",
                    // color: (theme) => theme.palette.primary.main,
                    zIndex: 1000,
                    transition: "all 0.3s",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              )}
          </>
        )}
      </BootstrapDialog>
      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}
          sx={{ zIndex: 1300 }}
        >
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}
          >
            <Box p={1}>
              {accessForDeleteCard && (
                <DeleteCard
                  cardId={cardId}
                  boardId={boardId}
                  setShowDetails={setShowDetails}
                  // disableCard={disableCard}
                  // isCompletedDependency={isCompletedDependency}
                  // parentCardId={parentCardId}
                />
              )}
              {card && (
                <ChangeBoard
                  cardId={cardId}
                  card={card}
                  // disableCard={disableCard}
                  // isCompletedDependency={isCompletedDependency}
                  // parentCardId={parentCardId}
                />
              )}
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
      {/* </React.Fragment> */}
    </>
  );
}
