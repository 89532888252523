import React from "react";
import Avatar from "../../../../../components/Avatar";
import { Grid, Tooltip } from "@mui/material";
import moment from "moment";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import { createAvtarName, hasImage } from "../../../../../utils/helpers";
import OpenCardDetail from "../../Kanban/openCardDetail";
import { useResponsive } from "../../../../../hooks/useResponsive";

function ShowComment({ comment, boardName }) {
  const navigate = useNavigate();
  const [data, updateSearchParams] = useCustomSearchParams("card_id");
  const isMobile = useResponsive("down", "md");
  const openBoard = () => {
    navigate(`/board/${boardName?.data?.data?.responseData?.board_id}`);
  };

  return (
    <>
      <OpenCardDetail />
      {comment ? (
        <Grid
          container
          spacing={2}
          className="worklog-item d-flex justify-content-space-between">
          <Grid item xs={isMobile ? 12 : 9}>
            <div className="d-flex w-100">
              <Avatar
                // key={`user-avtar-${el.user_id}`}
                username={`${comment?.username}`}
                user_image={comment?.user_image}
                title={`${comment?.username}`}
                className="user-avtar-dropdown user-avatar mr-8"
                style={
                  !hasImage(comment?.user_image) && {
                    bgcolor: comment?.user_profile_color?.background,
                    color: comment?.user_profile_color?.text,
                  }
                }>
                {createAvtarName(`${comment?.username}` || "")}
              </Avatar>
              <div className="w-100">
                <div className="d-flex justify-content-space-between ">
                  <div className="d-flex align-items-center">
                    <Tooltip
                      title={boardName?.data?.data?.responseData?.board_name}>
                      <a
                        className="board-anquor cursor-pointer"
                        onClick={openBoard}>
                        {`${boardName?.data?.data?.responseData?.key}`.toUpperCase()}
                      </a>
                    </Tooltip>
                    -
                    <a
                      className="board-anquor cursor-pointer mr-1"
                      onClick={() => {
                        updateSearchParams("card_id", comment?.card_id);
                      }}>
                      {comment?.card_id}:
                    </a>
                    <p className="worklog-title mr-2">{comment?.username}</p>
                  </div>
                </div>
                <p
                  className="worklog-worklog comment-content"
                  dangerouslySetInnerHTML={{
                    __html: comment?.comment,
                  }}></p>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 3}
            sx={{ paddingTop: isMobile && "0px !important" }}>
            <div className="right-details d-flex">
              <p className="worklog-date-time comment-date comment">
                Commented on{" "}
              </p>
              <p className="worklog-date-time comment-date">
                {isMobile
                  ? moment
                      .utc(comment?.comment_time)
                      .local()
                      .format("MMM D, YYYY [at] h:mm A")
                  : moment
                      .utc(comment?.comment_time)
                      .local()
                      .format("MMMM D, YYYY [at] h:mm A")}
              </p>
            </div>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default ShowComment;
