import React, { useState } from "react";
import {
  DateRangePicker,
  DateRange as DateRangeMobile,
} from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Button } from "@mui/material";

import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import { useResponsive } from "../../../../../hooks/useResponsive";

const WorkflowDateRangeFilter = ({ onClose }) => {
  const [Olddate, updateParams, removeSearchParam] =
    useCustomSearchParams("missingStatus");
  const date = JSON.parse(Olddate);
  const isMobile = useResponsive("down", "md");
  // console.log("date11",date)
  const [selectionRange, setSelectionRange] = useState([
    date
      ? {
          startDate: new Date(date.startDate),
          endDate: new Date(date.endDate),
          key: "selection",
        }
      : {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
  ]);

  const handleSelect = (ranges) => {
    // console.log(ranges);

    setSelectionRange([ranges.selection]);
    // Handle your logic here
  };

  const setData = () => {
    // console.log("date",JSON.stringify(selectionRange[0]));
    updateParams("missingStatus", JSON.stringify(selectionRange[0]));
    onClose();
  };

  return (
    <div className="d-grid">
      {isMobile ? (
        <DateRangeMobile
          ranges={selectionRange}
          onChange={handleSelect}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          direction="horizontal"
        />
      ) : (
        <DateRangePicker
          ranges={selectionRange}
          onChange={handleSelect}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          direction="horizontal"
        />
      )}
      <div className={`d-flex ${isMobile ? "mt-2" : ""}`}>
        <Button
          onClick={() => {
            // setSelectionRange([0])
            onClose();
            removeSearchParam("missingStatus");
          }}
          className="reset-date-picker">
          Reset
        </Button>
        <Button
          onClick={setData}
          className="submit-date-picker"
          variant="contained">
          Submit
        </Button>
      </div>
    </div>
  );
};

export default WorkflowDateRangeFilter;
