import axios from "axios";
import { localStorageInstance } from "../utils/localStorage";

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "",
});

Api.interceptors.request.use(function (response) {
  const token = localStorageInstance.getItem("token");
  if (token !== undefined && token !== null) {
    response.headers["token"] = `${token}`;
  }
  return response;
});

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      console.log("inside - axios response interceptors with 401 status code");
      localStorageInstance.clear();
      // alert("in axios intercepter");
      // Store the attempted URL in localStorage or sessionStorage
      const attemptedUrl = `${window.location.pathname}${window.location.search}`;

      // Redirect to the login page
      window.location = `${window.location.origin}?url=${attemptedUrl}`;
    }
    return Promise.reject(error);
  }
);

export default Api;
