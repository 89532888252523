import * as React from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import downloadTemplate from "../../../../../styles/svg/download-attachment.svg";
import uploadCloudImg from "../../../../../styles/svg/uploadCloudCSV.svg";
import ImportCardFromCSV from "../importCardsfromCSV/ImportCardFromCSV";
import "./UploadCSV.scss";
import useDownloader from "react-use-downloader";
import { useResponsive } from "../../../../../hooks/useResponsive";
import Papa from "papaparse";

export default function UploadCSV({ openCSV, closeCSV }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openImportCard, setOpenImportCard] = useState(false);
  const [validatedData, setValidatedData] = useState(null); // State to store validated data
  const [checkError, setCheckError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isMobile = useResponsive("down", "md");
  const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".csv",
    onDrop: (acceptedFiles) => {
      handleCSV(acceptedFiles);
      console.log("Accepted files:", acceptedFiles);
    },
  });

  const handleCSV = (files) => {
    const file = files[0];
    console.log("CSVTEXT", file);

    if (file) {
      Papa.parse(file, {
        header: true, // Treat the first row as headers
        skipEmptyLines: true, // Skip empty lines
        complete: function (results) {
          if (!results.data || results.data.length === 0) {
            console.error("No data found in the CSV file.");
            alert("The CSV file is empty or contains no valid data.");
            return; // Exit the function early
          }
          // Handle parsed results here
          console.log("Parsed Data:", results.data);
          const headers = Object.keys(results.data[0]);
          parseCSV(headers, results.data);
        },
        error: function (error) {
          console.error("Error reading CSV:", error);
        },
      });
    } else {
      alert("Please upload a CSV file.");
    }
  };

  const parseCSV = (headers, csvText) => {
    const isValid = validateCSVData(headers);

    if (isValid) {
      setErrorMessage("");
      setCheckError(false);
      setValidatedData(csvText);
      console.log("Validated Data:");
      setOpenImportCard(true);
    }
  };

  const validateCSVData = (headers) => {
    const expectedColumns = ["title", "description", "allocated_hours"];

    if (headers.join(",") !== expectedColumns.join(",")) {
      setErrorMessage("CSV header does not match expected format.");
      console.error("CSV header does not match expected format.");
      return false;
    }

    const missingColumns = expectedColumns.filter(
      (column) => !headers.includes(column)
    );
    if (missingColumns.length > 0) {
      setErrorMessage(`Missing columns: ${missingColumns.join(", ")}`);
      console.error(`Missing columns: ${missingColumns.join(", ")}`);
      return false;
    }
    return true;
  };
  // console.log("LOADER", loading);
  const handleTamplateDownload = (e) => {
    console.log("URL", process.env.REACT_APP_BASE_URL);
    download(
      `${process.env.REACT_APP_BASE_URL}/downloads/card_template/card_template.csv`,
      "card_template.csv"
    );
  };

  const handleClose = () => {
    closeCSV(false);
  };

  return (
    <React.Fragment>
      {openImportCard && (
        <ImportCardFromCSV
          openImportCard={openImportCard}
          closeImportCard={setOpenImportCard}
          closeCSV={closeCSV}
          data={validatedData}
        />
      )}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh !important",
            "& .MuiPaper-root": {
              borderRadius: "8px",
              maxWidth: "749px",
            },
          },
        }}
        // fullScreen={!isMobile}
        open={openCSV}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="uploadCSV-title" id="responsive-dialog-title">
          {"Upload File"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: isMobile ? 2 : 9,
            padding: "8px",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent
          sx={{
            padding: isMobile ? "20px 14px" : "129px 109px 210px 109px",
          }}
        >
          {errorMessage && (
            <div className="invalidCSVError">{errorMessage}</div>
          )}

          <div
            className={`uploadCSV-box ${isDragActive ? "drag-active" : ""}`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <img src={uploadCloudImg} alt="Upload Cloud" />
            <div className="p-1">Select a File and drag and drop here</div>
            <div className="p-2">We Support CSV file here</div>
            <Button className="select-file-btn">
              <input
                type="file"
                hidden
                accept=".csv"
                onChange={(e) => handleCSV(e.target.files)}
              />
              SELECT FILE
            </Button>
          </div>

          <div className="mt-4 d-flex justify-content-end">
            <img src={downloadTemplate} alt="" className="mr-2" />
            <Link
              component="button"
              variant="body2"
              onClick={(e) => handleTamplateDownload(e)}
            >
              Download Template
            </Link>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
