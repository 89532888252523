import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import toast from "react-hot-toast";
import useDownloader from "react-use-downloader";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCardAttachment } from "../../../../../utils/reactQuery/cards";
import {
  downloadCardAttachment,
  deleteCardAttachment,
} from "../../../../../sevices/apiCards";
import CustomDateFormatter from "../../dateTimeFormateConverter/dateTimeFormateConverter";
import ConfirmationDialog from "../../../../../components/Dialog/ConfirmationDialog";
import downloadImg from "../../../../../styles/svg/download.svg";
import imageInAttachmentIcon from "../../../../../styles/svg/imageInAttachmentIcon.svg";
import docsInAttachmentIcon from "../../../../../styles/svg/docsInAttachmentIcon.svg";
import deleteImg from "../../../../../styles/svg/delete.svg";
import "./showDetailAttachment.scss";
import addfile from "../../../../../styles/svg/add-file.svg";
import AttachFile from "../components/changeBoard/attachFile/AttachFile";
import SlideshowPopUp from "../attachedSlideShow/SlideshowPopUp";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useResponsive } from "../../../../../hooks/useResponsive";
import { Stack } from "@mui/material";

export default function AttachmentCard({ cardId, setAttachmentNo }) {
  const [deleteAttachment, setDeleteAttachment] = useState(false);
  const [deleteAttachmentId, setDeleteAttachmentId] = useState(null);
  const [showAttachmentSlideShow, setShowAttachmentSlideShow] = useState(false);
  const [addFile, setAddFile] = useState(false);
  const [attachmentId, setAttachmentId] = useState(null);
  const queryClient = useQueryClient();
  const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();
  const isMobile = useResponsive("down", "md");
  const {
    isLoading: isLoading,
    cardAttachment,
    isError: isError,
  } = useCardAttachment(cardId);
  // console.log("cardAttachment....",cardAttachment.length)

  const attachments = [];

  const { mutate: deleteCardAttachmentMutate } = useMutation({
    mutationFn: deleteCardAttachment,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cardAttachment`, cardId],
      });
      // setLoading(false);
      // console.log(res);
      //    reset();
      // toast.success(res.data.message);
      handleClose();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      // setLoading(false);
      handleClose();
    },
  });
  const handleDelete = (event) => {
    setDeleteAttachment(true);
  };
  const handleClose = () => {
    setDeleteAttachment(false);
  };
  function callback() {
    // setLoading(true);
    deleteCardAttachmentMutate(deleteAttachmentId);
  }

  const handleSlideShowAttachment = (attachmentId) => {
    setAttachmentId(attachmentId);
    setShowAttachmentSlideShow(true);
  };

  const handleView = (attachementId) => {
    const getResponse = downloadCardAttachment(attachementId);
    // console.log("DOWNLOAD...", getResponse);
    getResponse
      .then((res) => {
        console.log("handle view of preview file", res.data);
        window.open(
          `${process.env.REACT_APP_BASE_URL}/${res.data.data.responseData.url}`,
          `${res.data.data.responseData.filename}`,
          "_blank"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDownload = (filename) => {
    // e.stopPropogation()
    console.log("filename....", filename);
    const getResponse = downloadCardAttachment(filename);
    // console.log("DOWNLOAD...", getResponse);
    getResponse
      .then((res) => {
        // console.log("getResponse", res.data);
        download(
          `${process.env.REACT_APP_BASE_URL}/${res.data.data.responseData.url}`,
          `${res.data.data.responseData.filename}`
        );
        //   const binaryData = res.data; // Adjust accordingly if data is nested

        //   // Create a Blob from the binary data
        // const blob = new Blob([binaryData], { type: 'application/octet-stream' });

        // // Create a temporary anchor element
        // const link = document.createElement('a');
        // link.href = URL.createObjectURL(blob);
        // link.download = filename;

        // // Trigger the download by simulating a click event
        // link.click();

        // // Clean up resources
        // URL.revokeObjectURL(link.href);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log("CARDATTACHMENT.....", cardAttachment?.data?.data?.responseData);
  // console.log("cardAttachment?.data?.totalRecord",cardAttachment?.data?.data?.totalRecord)
  return (
    <>
      {showAttachmentSlideShow && attachmentId && (
        <SlideshowPopUp
          attachmentId={attachmentId}
          slideImages={attachments}
          showAttachmentSlideShow={showAttachmentSlideShow}
          setShowAttachmentSlideShow={setShowAttachmentSlideShow}
        />
      )}

      {deleteAttachment && (
        <ConfirmationDialog
          open={deleteAttachment}
          handleClose={handleClose}
          message={
            "Are you sure to you want to delete this item? This action can not be undone"
          }
          callback={callback}
          // loading={loading}
          btnTitle={"Delete"}
          title={"Delete"}
        />
      )}
      {/* <div className="attachment-heading">
              <p className="attachment-para">Attachments ()</p>
            </div> */}

      {cardAttachment?.data?.data?.totalRecord !== 0 &&
        (isMobile ? (
          <Stack direction="row" alignItems="end" spacing={2} my={1}>
            <p className="attachment-para">
              Attachments ({cardAttachment?.data?.data?.totalRecord}){" "}
            </p>
            {cardAttachment?.data?.data?.totalRecord !== 0 && (
              <AttachFile cardId={cardId} isIconBtn={true} />
            )}
          </Stack>
        ) : (
          <p className="attachment-para">
            Attachments ({cardAttachment?.data?.data?.totalRecord})
          </p>
        ))}
      {cardAttachment?.data?.data?.totalRecord !== 0 && (
        <div className={isMobile ? "mobile-attachment-box" : "attachment-box"}>
          {cardAttachment?.data?.data?.responseData
            ?.sort((a, b) => a.card_attachment_id - b.card_attachment_id)
            .map((item) => {
              attachments.push(item);
              // console.log("attach",item.attachment_type)
              return (
                <>
                  <Box
                    onClick={() => {
                      item.attachment_type == "pdf"
                        ? handleView(item.card_attachment_id)
                        : handleSlideShowAttachment(item.card_attachment_id);
                    }}
                    height={isMobile ? 0 : 121}
                    width={150}
                    my={0}
                    mx={0}
                    // display="flex"
                    alignItems="center"
                    gap={0}
                    p={0}
                    sx={{
                      boxShadow: "0px 4px 50px 0px rgba(27, 62, 97, 0.25)",
                      borderRadius: "6px",
                      marginRight: isMobile ? "50px" : "9px",
                      marginTop: "7px",
                      // Add other styles as needed
                    }}
                  >
                    {isMobile ? (
                      ""
                    ) : (
                      <div className="attachment-img">
                        <div className="icons">
                          {/* <div
                        className="attachment-view"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleView(item.card_attachment_id);
                        }}>
                        <RemoveRedEyeIcon sx={{ width: "17px", height:'20px', marginLeft: '2px' }} />
                      </div> */}
                          <div
                            className="attachment-download"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDownload(item.card_attachment_id);
                            }}
                          >
                            <img src={downloadImg} alt="" />
                          </div>
                          <div
                            className="attachment-delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteAttachmentId(item.card_attachment_id);
                              handleDelete();
                            }}
                          >
                            <img src={deleteImg} alt="" />
                          </div>
                        </div>

                        {isMobile ? (
                          ""
                        ) : item.attachment_type === "jpg" ||
                          item.attachment_type === "jpeg" ||
                          item.attachment_type === "png" ? (
                          <img
                            className="attachment-img-data"
                            src={process.env.REACT_APP_BASE_URL + item.data}
                            // src={"data:image/png;base64," + item.data}
                            alt=""
                          />
                        ) : (
                          <div className="attachment-img-type">
                            <p>{item.attachment_type}</p>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="attachment-detail">
                      <div className={isMobile ? "d-flex" : ""}>
                        {isMobile ? (
                          item.attachment_type === "jpg" ||
                          item.attachment_type === "jpeg" ||
                          item.attachment_type === "svg" ||
                          item.attachment_type === "png" ? (
                            // <img
                            //   className="attachment-img-data"
                            //   src={process.env.REACT_APP_BASE_URL + item.data}
                            //   // src={"data:image/png;base64," + item.data}
                            //   alt=""
                            // />
                            <div>
                              <img src={imageInAttachmentIcon} alt="" />
                            </div>
                          ) : (
                            // <div className="attachment-img-type">
                            //   <p>{item.attachment_type}</p>
                            // </div>
                            <div>
                              <img src={docsInAttachmentIcon} alt="" />
                            </div>
                          )
                        ) : (
                          ""
                        )}
                        <div className={isMobile ? "ml-2" : ""}>
                          {/* <p className="attachment-name">{item.attachment}</p> */}
                          {isMobile ? (
                            <p className="mobile-attachment-name">
                              {item.attachment_name}
                            </p>
                          ) : (
                            <p className="attachment-name">
                              {item.attachment_name}
                            </p>
                          )}

                          <div className="attachment-date">
                            <CustomDateFormatter date={item.date_added} />
                          </div>
                        </div>
                      </div>
                      {isMobile && (
                        <div className="icons">
                          {/* <div
                        className="attachment-view"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleView(item.card_attachment_id);
                        }}>
                        <RemoveRedEyeIcon sx={{ width: "17px", height:'20px', marginLeft: '2px' }} />
                      </div> */}
                          <div
                            className="attachment-download"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDownload(item.card_attachment_id);
                            }}
                          >
                            <img src={downloadImg} alt="" />
                          </div>
                          <div
                            className="attachment-delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteAttachmentId(item.card_attachment_id);
                              handleDelete();
                            }}
                          >
                            <img src={deleteImg} alt="" />
                          </div>
                        </div>
                      )}
                      {/* <p>{item.date_added}</p> */}
                    </div>
                  </Box>
                </>
              );
            })}
          {!isMobile && cardAttachment?.data?.data?.totalRecord !== 0 && (
            <AttachFile cardId={cardId} isIconBtn={true} />
          )}

          {/* <div className="new-add-file" >
            <img src={addfile} alt="" />
          </div> */}
        </div>
      )}
    </>
  );
}
