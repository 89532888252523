import React from "react";
import WorkflowDateRangeFilter from "./DateRangeFilter";
import DateRangeFilterButton from "./DateRangeFilterButton";
import { useResponsive } from "../../../../../hooks/useResponsive";
import { Grid, Stack, Typography, useScrollTrigger } from "@mui/material";
const Header = () => {
  const isMobile = useResponsive("down", "md");
  const trigger = useScrollTrigger();

  return (
    <>
      {isMobile ? (
        <Grid
          container
          xs={12}
          className="remove-container-width fixed-ui"
          sx={{ top: trigger ? "0px" : isMobile ? "51px" : "" }}
          justifyContent={"center"}
          boxShadow={" 0px 1px 7px rgba(0, 0, 0, 0.3)"}
          pb={"16px !important"}
          pt={trigger ? "16px !important" : "20px !important"}
          alignItems={"center"}>
          <Grid
            item
            xs={5}
            md={12}
            lg={3}
            className="d-flex align-items-center">
            <Typography fontWeight={600}>No Status Report</Typography>
          </Grid>
          <Grid item xs={7} md={7} lg={9}>
            <Stack justifyContent="end">
              <DateRangeFilterButton />
            </Stack>{" "}
          </Grid>
        </Grid>
      ) : (
        <div className="report-header">
          <p className="report-header-name">No Status Report</p>
          <div className="date-filter">
            <p className="filterBy">Filter By Date:</p>
            <DateRangeFilterButton />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
