import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "../../../../../schemas/worklogDetail";
import moment from "moment";
import Button from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import "./addWorklogDialog.scss";
import TextField from "@mui/material/TextField";
import TextEditor from "../../textEditor/textEditor";
import DateTimePicker from "../../datePickerCalender/datePicker";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { createWorklog } from "../../../../../sevices/apiWorklog";
import { useResponsive } from "../../../../../hooks/useResponsive";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AddWorklogCustomizedDialogs({
  showEdit,
  setShowEdit,
  heading,
  buttonName,
  addWorklog,
  setAddWorklog,
  cardId,
}) {
  const [textContent, setTextContent] = useState("");
  const [hours, setHours] = useState("");
  const [date, setDate] = useState(moment());
  const [isloading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const description = "";
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "md");
  // React Hook Form
  const { register, handleSubmit, reset, getValues, setValue, formState } =
    useForm({
      defaultValues: {
        // date ? moment(date) : moment()
        date_time: moment(),
      },
      resolver: yupResolver(schema),
    });
  const { errors } = formState;
  // console.log("getvalue",errors)
  const { mutate: createWorklogmutate } = useMutation({
    mutationFn: createWorklog,
    onSuccess: (res) => {
      // queryClient.invalidateQueries({
      //   queryKey: [`boards`],
      // });
      // setComment('')
      queryClient.invalidateQueries({
        queryKey: [`worklog`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`history`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      // setLoading(false);
      // console.log(res);
      //    reset();
      // setComment('')
      toast.success(res.data.message);

      // setOpenDialog(false);

      setLoading(false);
      setAddWorklog(false);
    },
    onError: (error) => {
      console.error("Error assig board", error);
      toast.error(error.response.data.message);
      // setLoading(false);
      // setOpenDialog(false);
      // setLoading(false)
    },
  });

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };
  const handleClose = () => {
    if (addWorklog === true) {
      setAddWorklog(false);
    }
    if (showEdit === true) {
      setShowEdit(false);
    }
  };
  const handleHoursChange = (e) => {
    const value = e.target.value;
    setHours(value);
    // if (value.trim() === "") {
    //   setError("field required");
    // } else if (isNaN(value)) {
    //   // Validate if the input is a number
    //   setError("Please enter only numeric characters.");
    // } else {
    //   setError("");
    // }
  };

  // const handleSave = (e) => {
  //   // console.log("EVENTDATE...",date)
  //   const newDate = moment(date).format("YYYY-MM-DDTHH:mm:ss");
  //   // console.log("EVENTDATE...",newDate)
  //   setLoading(true);
  //   e.preventDefault();
  //   createWorklogmutate({
  //     card_id: cardId,
  //     work_performed: textContent,
  //     hours_spent: hours,
  //     date_time: newDate,
  //   });
  //   // setAddWorklog(false);
  // };
  // console.log("DATE.....", date + typeof date);

  const submit = (data) => {
    setLoading(true);
    createWorklogmutate({
      card_id: cardId,
      ...data,
    });
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={addWorklog}
        fullScreen={fullScreen}
        sx={{ borderRadius: "20px", height: "fitContent" }}
        // PaperComponent={{sx:{height:"100%"}}}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            bgcolor: "rgba(242, 246, 254, 1)",
            // color: "#1B3E61",
            // fontWeight: "bolder",
            // fontFamily: "DM Sans",
          }}
          id="customized-dialog-title"
          className="mobile-addWorklog-title"
        >
          {" "}
          <div className="add-worlog-title-dialog">{heading}</div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: isMobile ? 1 : 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form onSubmit={handleSubmit(submit)}>
            <Typography gutterBottom>
              <div className={isMobile ? "" : "work-hour-box"}>
                <p className="work-hour-title">Work Hours</p>
                <TextField
                  // id="outlined-basic"
                  id="hours_spent"
                  name="hours_spent"
                  variant="outlined"
                  className="work-hour"
                  error={errors?.hours_spent ? true : false}
                  helperText={
                    errors?.hours_spent && errors?.hours_spent?.message
                  }
                  // value={hours}
                  // onChange={handleHoursChange}
                  {...register("hours_spent")}
                />

                {/* <div className="number-error-message">
                {error && <p className="number-error-message-field">{error}</p>}
              </div> */}
              </div>
            </Typography>
            <Typography>
              <p className="work-discription-title">Work Description</p>
              <div className="text-editor">
                <TextEditor
                  id="work_performed"
                  name="work_performed"
                  // setTextContent={setTextContent}
                  setTextContent={(worklog) =>
                    setValue("work_performed", worklog)
                  }
                  description={description}
                  // {...register("work_performed")}
                />

                <p className="error-message">
                  {errors?.work_performed && errors?.work_performed?.message}
                </p>
              </div>
            </Typography>
            <Typography>
              <p className="work-date-title">Date</p>
              <div className="work-date-input">
                <DateTimePicker
                  setDate={(date) => setValue("date_time", date)}
                  id="date_time"
                  name="date_time"
                />
                <p className="error-message">
                  {errors?.date_time && errors?.date_time?.message}
                </p>
              </div>
            </Typography>
            <div className="worklog-btn">
              <Button
                className="worklog-btn-save"
                autoFocus
                variant="contained"
                disabled={isloading}
                // onClick={handleSave}
                type="submit"
              >
                <div className="btn-content">{buttonName}</div>
              </Button>
              <Button
                className="worklog-btn-cancel"
                autoFocus
                variant="outlined"
                disabled={isloading}
                onClick={handleClose}
              >
                <div className="btn-content">Cancel</div>
              </Button>
            </div>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
